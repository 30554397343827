import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors, Queries } from 'styles';
import { useTranslation } from 'react-i18next';

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const GalleryImage = styled.div`
  padding: 0.5rem;
  flex-basis: 100%;

  @media ${Queries.sm} {
    flex-basis: 50%;
  }

  @media ${Queries.lg} {
    flex-basis: 33.3%;
  }

  & > .imgContainer {
    padding: 0.5rem;
    border: 1px solid ${Colors.grey_lite};

    .ratioBox {
      position: relative;
      padding-top: 60%;
      overflow: hidden;

      & > img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s ease-in-out transform;
        cursor: pointer;

        &:hover {
          transform: scale(1.15);
        }
      }
    }
  }
`;

const LightBox = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  position: fixed;
  background-color: ${Colors.shadow};
  display: ${(props) => (props.isActive ? 'block' : 'none')};

  .lightBoxContent {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    background-color: ${Colors.bg_lite};
    box-shadow: 0px 4px 8px ${Colors.shadow_lite};

    & > img {
      width: 100%;
      max-height: 90vh;
      object-fit: cover;
    }

    .viewFull {
      padding: 0.5rem 1rem 0.25rem;
    }
  }
`;

const ImageGallery = ({ data }) => {
  const { t } = useTranslation();
  const [currImg, setCurrImg] = useState(null);
  return (
    <GalleryContainer>
      <LightBox onClick={() => setCurrImg(null)} isActive={currImg !== null}>
        <div onClick={(e) => e.stopPropagation()} className="lightBoxContent">
          <img src={currImg} alt="" />
          <div className="viewFull">
            <a href={currImg}>{t('view_original')}</a>
          </div>
        </div>
      </LightBox>
      {data.gallery.map((item, idx) => (
        <GalleryImage key={idx}>
          <div className="imgContainer">
            <div
              onClick={() => setCurrImg(item.image.url)}
              className="ratioBox"
            >
              <img src={item.image.url} alt="" />
            </div>
          </div>
        </GalleryImage>
      ))}
    </GalleryContainer>
  );
};

export default ImageGallery;

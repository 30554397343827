import React from 'react';
import Layout from 'layouts/Layout';
import Container from 'layouts/Container';
import ImageGallery from 'components/ImageGallery';
import SEO from 'components/seo';
import { getLangData } from 'utils';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Colors, Queries } from 'styles';
import { graphql } from 'gatsby';

const GalleryContent = styled.div`
  margin: 2rem 0;
  background-color: ${Colors.bg_lite};
  padding: 1.5rem 1rem;

  & > .title {
    font-size: 2rem;
    color: ${Colors.gdmc_blue};
    text-align: center;
    margin-bottom: 1rem;
  }
`;

const Gallery = ({ location, data }) => {
  const { i18n, t } = useTranslation();
  const galleryData = getLangData(data.allPrismicGallery.edges, i18n.language);
  return (
    <Layout location={location}>
      <SEO lang={i18n.language} title={t('gallery')} />
      <Container>
        <GalleryContent>
          <h1 className="title">{galleryData.title.text}</h1>
          <ImageGallery data={galleryData} />
        </GalleryContent>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query GalleryQuery {
    allPrismicGallery {
      edges {
        node {
          data {
            gallery {
              image {
                url
              }
            }
            title {
              text
            }
          }
          lang
        }
      }
    }
  }
`;

export default Gallery;
